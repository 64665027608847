import axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import { StorageService } from './storage.js'

axios.defaults.baseURL = process.env.VUE_APP_API_PATH;
axios.defaults.headers.post['Content-Type'] = 'application/json';

// If token exists set header
let token = StorageService.getToken();
if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
}

export const ApiService = {
    init() {
        setupCache(axios, { interpretHeader: false });
    },
    removeHeader() {
        axios.defaults.headers.common = {}
    },
    get(apiPath, options = { cache:  false}) {
        return axios.get(apiPath, options)
    },
    download(apiPath) {
        return axios({
            url: apiPath,
            method: 'GET',
            responseType: 'blob', // important
        })
    },
    post(apiPath, data) {
        return axios.post(apiPath, data);
    },

    postPDF(apiPath, data) {
        return axios.post(apiPath, data, {
            headers: {
                'Content-Type': 'application/pdf', // Indicamos que estamos enviando un PDF
                'Accept': '*/*', 
            },
            responseType: 'blob'  // Si esperas que la respuesta también sea un archivo binario (PDF)
        });
    },
    
    postNoContentType(apiPath, data) {
        return axios.post(apiPath, data, {
            headers: {
                'Content-Type': undefined // Esto evitará que se establezca un Content-Type
            }
        });
    },

    put(apiPath, data) {
        return axios.put(apiPath, data)
    },

    delete(apiPath) {
        return axios.delete(apiPath)
    },
    axios() {
        return axios;
    },
}
